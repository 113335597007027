import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-people',
  templateUrl: './people.component.html',
  styleUrls: ['./people.component.css']
})

export class PeopleComponent implements OnInit {

  keremImagePath: string;
  tansuImagePath: string;
  bortecineImagePath: string;
  mervenurImagePath:string;
  edaImagePath:string;
  hummairaImagePath:string;
  canberkImagePath:string;
  kaganImagePath:string;
  farukImagePath:string;
  azmatImagePath:string;
  aliImagePath:string;
  hakancanImagePath:string;
  enderImagePath:string;
  sercinImagePath:string;

  keremCVPath: string;

  constructor() {
    this.keremImagePath = '../../assets/images/pekkan.jpg'

    this.tansuImagePath = '../../assets/images/tansu.jpeg'
    this.bortecineImagePath = '../../assets/images/bortecine.png'
    this.mervenurImagePath = '../../assets/images/mervenur.png'
    this.edaImagePath = '../../assets/images/eda.jpg'
    this.hummairaImagePath = '../../assets/images/Hummaira.png'
    this.canberkImagePath = '../../assets/images/canberk.png'
    this.kaganImagePath = '../../assets/images/kagan.jpg'
    this.farukImagePath = '../../assets/images/faruk.jpg'
    this.azmatImagePath = '../../assets/images/azmat.jpg'
    this.aliImagePath = '../../assets/images/ali.jpeg'
    this.hakancanImagePath = '../../assets/images/hakancan.png'
    this.enderImagePath = '../../assets/images/ender.jpg'
    this.sercinImagePath = '../../assets/images/sercin.jpg'

    
    this.keremCVPath = "../../assets/docs/cv_s/pekkan_cv.pdf";
  }

  ngOnInit() {

  }
}
